<div class="leaderboard" [ngStyle]="{'background-repeat': isMobileView ? 'no-repeat' : ''}">
    <video *ngIf="(isCustomLogo || isGenericPage) && !isMobileView" (loadeddata)="onVideoLoaded($event)" (pause)="onVideoPause($event)" autoplay loop id="backgroundVideo" src="https://{{cloudfrontOpenURL}}/categories/citadelorlando/retrader-background-video.mp4"></video>
    <div class="leaderboard-header" [ngClass]="isCustomLogo || isGenericPage ? 'custom-header' : ''" [ngStyle]="{'margin-top': isMobileView ? '0' : '', 'justify-content': 'center', 'align-items': 'center', 'padding-right': '7%'}">
      <div class="title" *ngIf="!isCustomLogo && !isMobileView && !isNeuroIPS && !isGenericPage" [ngStyle]="{'margin-left':listLength === 0 ? '7%' : '6%'}"> {{ leaderboardTitle }} </div>
      <div class="title-image" *ngIf="(isGenericPage ||isNeuroIPS) && !isMobileView">
        <img src="/assets/leaderboard-title.png" alt="{{leaderboardTitle}}">
      </div>
        <div class="custom-title logo" *ngIf="isCustomLogo">
            <img
            src="https://{{cloudfrontOpenURL}}/categories/citadelorlando/citadel_retrader_leaderboard_title-transparent.png"
            alt="Logo">
        </div>
        <div class="logo" *ngIf="showLogo && !isCustomLogo" [ngClass]="category?.small_image ? 'custom-header-logo' : ''">
            <img
            src="https://{{cloudfrontOpenURL}}/categories/{{category?.small_image}}"
            alt="Logo">
        </div>
        <div class="logo" *ngIf="isCustomLogo" [ngClass]="isCustomLogo ? 'custom-header-logo' : ''">
            <img
            src="https://{{cloudfrontOpenURL}}/categories/{{category?.small_image}}"
            alt="Logo">
        </div>
        <div class="logo" *ngIf="!showLogo && !isCustomLogo" [ngClass]="isGenericPage ? 'custom-generic-logo' : ''">
          <img
            src="https://{{cloudfrontOpenURL}}/categories/Amplify/amplifyme-logo.png"
            alt="Logo"
            class="{{isGenericPage ? 'generic-logo' : 'amplify-logo'}}">
        </div>
    </div>
    <ng-container *ngIf="isMobileView">
        <div class="leaderboard-body leaderboard-body-mobile">
            <div class="select-container">
                <div class="custom-select-wrapper">
                    <select class="{{isNeuroIPS ? 'mobile-select' : ''}}" id="tableSelect" [(ngModel)]="selectedTableId" (change)="onTableSelectionChange()">
                        <option *ngFor="let config of games" [ngValue]="config.id">
                            {{ config.title }}
                        </option>
                    </select>
                </div>
                <div *ngIf="isNeuroIPS" class="custom-select-wrapper">
                    <select class="{{isNeuroIPS ? 'mobile-select' : ''}}" id="dateSelect" [(ngModel)]="selectedDateId" (change)="onDateSelectionChange()">
                        <option *ngFor="let availableDate of availableNeuroIPSDates" [ngValue]="availableDate.id">
                            {{ availableDate.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="game-header-title"> {{ leaderboardTitle }} </div>
        </div>
    </ng-container>
    <div class="leaderboard-body" [ngClass]="isCustomLogo || isGenericPage ? 'top-three' : ''" [ngStyle]="{'width': isMobileView ? '92%' : '', 'height': isMobileView ? '80%': ''}">
        <div class="index {{isNeuroIPS ? 'ips-index': ''}} {{isMobileView ? 'custom-index-mobile' : ''}} {{showStrategyAndPnL ? 'ips-index-pnl' : ''}}" [ngClass]="isCustomLogo || isGenericPage ? 'custom-index' : ''">
            <div *ngIf="isMobileView" class="game-header">
                <div class="game-header-title">
                    <div class="game-header-body">&nbsp;</div>
                    <div *ngIf="showStrategyAndPnL" class="game-header-body">&nbsp;</div>
                </div>
            </div>
            <div *ngIf="!isMobileView" class="game-header">
                <div class="game-header-title">&nbsp;</div>
                <div *ngIf="showStrategyAndPnL" class="game-header-body">&nbsp;</div>
                <div class="game-header-body">&nbsp;</div>
            </div>
            <div class="game-body" [ngClass]="isCustomLogo || isGenericPage? 'custom-game-body' : ''">
                <table *ngIf="games.length > 0">
                    <tbody>
                        <tr *ngFor="let num of indexArray;"><td> {{ num + 1 }} </td></tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ng-container  *ngIf="!isMobileView">
        <div class="game_1" *ngFor="let game of games.slice(0,6)" [ngClass]="isCustomLogo || isGenericPage ? 'custom-game' : ''" [ngStyle]="{'width': games.length <= 2 ? '45%' : ''}">
            <div *ngIf="!isGenericPage" class="game-header"  [ngClass]="isCustomLogo || isGenericPage ? game.headerStyle : 'game-header'">
                <div class="game-header-title"> {{ game.title }} </div>
                <div class="game-header-body"></div>
              <div *ngIf="showStrategyAndPnL" class="game-header-body strategy">
                <div *ngIf="game.strategyName; else missingStrategyName">{{game.strategyName}}</div>
                <ng-template #missingStrategyName><div class="emptyStrategy">&nbsp;</div></ng-template>

                <div *ngIf="game.PnL; else missingPnL">{{game.PnL | currency:'USD':'symbol':'1.0-2'}}</div>
                <ng-template #missingPnL><div class="emptyStrategy">&nbsp;</div></ng-template>
              </div>
                <div class="game-header-body">
                    <div> Player's Name </div>
                    <div> PNL </div>
                </div>
            </div>
          <div *ngIf="isGenericPage" class="game-header" [ngStyle]="{'background-color':game.headerColor, 'text-transform': 'uppercase'}">
            <div class="game-header-title"> {{ game.title }} </div>
              <div *ngIf="showStrategyAndPnL" class="game-header-body strategy">
                  <div *ngIf="game.strategyName; else missingStrategyName">{{game.strategyName}}</div>
                  <ng-template #missingStrategyName><div class="emptyStrategy">&nbsp;</div></ng-template>

                  <div *ngIf="game.PnL; else missingPnL">{{game.PnL | currency:'USD':'symbol':'1.0-2'}}</div>
                  <ng-template #missingPnL><div class="emptyStrategy">&nbsp;</div></ng-template>
              </div>
            <div class="game-header-body">
              <div> Player's Name </div>
              <div> PNL </div>
            </div>
          </div>
            <div class="game-body" [ngClass]="isCustomLogo || isGenericPage ? 'custom-game-body' : ''">
                <table>
                    <tbody>
                        <tr *ngIf="!leaderboard.hasOwnProperty(game.id) || leaderboard[game.id].length == 0"><td class="td_no_points"> There are no points for this game </td></tr>
                        <tr *ngFor="let player of leaderboard[game.id]?.slice(0, listLength)"><td class="td_points"> {{ player.first_name }} {{ player.last_name }} </td><td class="td_points"> <span class="show_points"> {{ player.points | currency:'USD':'symbol':'1.0-2' }} </span> </td></tr>
                    </tbody>
                </table>
            </div>
        </div>
        </ng-container>
        <ng-container *ngIf="isMobileView">
            <div class="game_1" [ngClass]="isCustomLogo || isGenericPage ? 'custom-game' : ''">
                <div class="game-header"
                     [ngStyle]="{'background-color':selectedTable?.headerColor, 'text-transform': 'uppercase'}">
                    <div class="game-header-title">
                        <div *ngIf="showStrategyAndPnL" class="game-header-body strategy">
                            <div *ngIf="selectedTable?.strategyName; else missingStrategyName">{{selectedTable?.strategyName}}</div>
                            <ng-template #missingStrategyName><div class="emptyStrategy">&nbsp;</div></ng-template>

                            <div *ngIf="selectedTable?.PnL; else missingPnL">{{selectedTable?.PnL | currency:'USD':'symbol':'1.0-2'}}</div>
                            <ng-template #missingPnL><div class="emptyStrategy">&nbsp;</div></ng-template>
                        </div>
                        <div class="game-header-body">
                            <div> Player's Name</div>
                            <div> PNL</div>
                        </div>
                    </div>
                </div>
                <div class="game-body" [ngClass]="isCustomLogo || isGenericPage ? 'custom-game-body' : ''">
                    <table>
                        <tbody>
                        <tr *ngIf="!leaderboard.hasOwnProperty(selectedTable?.id) || leaderboard[selectedTable?.id].length == 0">
                            <td class="td_no_points"> There are no points for this game</td>
                        </tr>
                        <tr *ngFor="let player of leaderboard[selectedTable?.id]?.slice(0, listLength)">
                            <td class="td_points"> {{ player?.first_name }} {{ player?.last_name }} </td>
                            <td class="td_points"><span
                                    class="show_points"> {{ player?.points | currency:'USD':'symbol':'1.0-2' }} </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
    </div>
</div>

