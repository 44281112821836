import { RouterModule, Routes } from "@angular/router";

import { AppusersComponent } from "./appusers/appusers.component";
import { AuthGuardService as AuthGuard } from "./services/auth-guard.service";
import { BundlesComponent } from "./bundles/bundles.component";
import { CategoriesComponent } from "./categories/categories.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { EventsComponent } from "./events/events.component";
import { GameGeneratorComponent } from "./game-generator/game-generator.component";
import { GamesComponent } from "./games/games.component";
import { GroupsComponent } from "./groups/groups.component";
import { HelpComponent } from "./help/help.component";
import { LiveleaderboardComponent } from "./liveleaderboard/liveleaderboard.component";
import { LoginComponent } from "./login/login.component";
import { NgModule } from "@angular/core";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { RedirectGuard } from "./services/redirect-guard.service";
import { SettingsComponent } from "./settings/settings.component";
import { DeleteAccountComponent } from "./delete-account/delete-account.component";

const routes: Routes = [
  {path: "liveleaderboard", component: LiveleaderboardComponent},
  {path: "liveleaderboard/CitadelNeurIPS", component: LiveleaderboardComponent,canActivate: [RedirectGuard]},
  {path: "liveleaderboard/CitadelNeurIPSdev1", component: LiveleaderboardComponent},
  {path: "liveleaderboard/CitadelNeurIPSdev2", component: LiveleaderboardComponent},
  {path: "liveleaderboard/CitadelNeurIPSdev3", component: LiveleaderboardComponent},
  {path: "liveleaderboard/CitadelNeurIPSdev4", component: LiveleaderboardComponent},
  {path: "liveleaderboard/CitadelNeurIPSDec11", component: LiveleaderboardComponent},
  {path: "liveleaderboard/CitadelNeurIPSDec12", component: LiveleaderboardComponent},
  {path: "liveleaderboard/CitadelNeurIPSDec13", component: LiveleaderboardComponent},
  {path: "liveleaderboard/CitadelNeurIPSDec14", component: LiveleaderboardComponent},
  {path: "liveleaderboard_citadelsecurities", component: LiveleaderboardComponent},
  {path: "liveleaderboard_baml", component: LiveleaderboardComponent},
  {path: "liveleaderboard_dare", component: LiveleaderboardComponent},
  {path: "leaderboard_citadelsecurities_ghc23", component: LiveleaderboardComponent},
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "games", component: GamesComponent, canActivate: [AuthGuard] },
  { path: "events", component: EventsComponent, canActivate: [AuthGuard] },
  {
    path: "categories",
    component: CategoriesComponent,
    canActivate: [AuthGuard],
  },
  { path: "bundles", component: BundlesComponent, canActivate: [AuthGuard] },
  { path: "groups", component: GroupsComponent, canActivate: [AuthGuard] },
  { path: "appusers", component: AppusersComponent, canActivate: [AuthGuard] },
  { path: "settings", component: SettingsComponent, canActivate: [AuthGuard] },
  {
    path: "games/generate",
    component: GameGeneratorComponent,
    canActivate: [AuthGuard],
  },
  { path: "help", component: HelpComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "account/delete", component: DeleteAccountComponent },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "**", component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
