/* tslint:disable:max-line-length */
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Category } from '../models/Category';
import { Game } from '../models/Game';
import { Leaderboard } from '../models/Leaderboard';
import { Settings } from '../models/Settings';
import { Title } from '@angular/platform-browser';
import { catchError } from "rxjs/operators";
import { forkJoin, of } from "rxjs";

@Component({
  selector: 'app-liveleaderboard',
  templateUrl: './liveleaderboard.component.html',
  styleUrls: ['./liveleaderboard.component.css']
})
export class LiveleaderboardComponent implements OnInit {

  public categoryId: number;
  public top: number = -1;
  public listLength: number;
  public category: Category = null;
  public cloudfrontOpenURL: string = "";
  public games: Game[] = [];
  public leaderboard = [];
  public indexArray: number[] = [];
  public paramsToSend: any = {dbName: "", dbColumn: "", searchText: "", page: 1, shownegative: "no"};
  public interval = null;
  public showLogo: boolean = false;
  public settings: Settings = null;
  public isCitadelSecurities = false;
  public isBaml = false;
  public isDare = false;
  public leaderboardTitle = "Live Leaderboard";
  public isCustomLogo = false;
  public isGenericPage = false;
  public defaultLightHeaderColor = '#558FF0';
  public defaultDarkHeaderColor = '#08225A';
  public shownegative = false;
  public showStrategyAndPnL = false;
  isMobileView = false;
  selectedTable: Game;
  selectedTableId: number;
  screenWidth = 1920;
  isNeuroIPS = false;
  availableNeuroIPSDates = [];
  selectedDate = null;
  selectedDateId: number;
  path = '';
  isProduction = false;

  constructor(private titleService: Title, private route: ActivatedRoute, private apiService: ApiService, public element: ElementRef, public router: Router) {
    this.titleService.setTitle('ReTrader - Live Leaderboard');
  }

  ngOnInit(): void {
    this.checkAndUpdateScreenSize();
    this.getDomainName();
    const fullPath = this.route.snapshot.pathFromRoot
      .map(route => route.url.map(segment => segment.path).join('/'))
      .join('');

      const predefinedRoutes = {
        'liveleaderboard/CitadelNeurIPSdev1': { categoryId: 27, top: 10, shownegative: false },
        'liveleaderboard/CitadelNeurIPSdev2': { categoryId: 28, top: 10, shownegative: false },
        'liveleaderboard/CitadelNeurIPSdev3': { categoryId: 29, top: 10, shownegative: false },
        'liveleaderboard/CitadelNeurIPSdev4': { categoryId: 31, top: 10, shownegative: false },
        'liveleaderboard/CitadelNeurIPSDec11': { categoryId: 353, top: 10, shownegative: false },
        'liveleaderboard/CitadelNeurIPSDec12': { categoryId: 354, top: 10, shownegative: false },
        'liveleaderboard/CitadelNeurIPSDec13': { categoryId: 355, top: 10, shownegative: false },
        'liveleaderboard/CitadelNeurIPSDec14': { categoryId: 374, top: 10, shownegative: false }
      };
    this.path = fullPath;
    if (predefinedRoutes[fullPath]) {
      this.setParams(predefinedRoutes[fullPath]);
      this.isNeuroIPS = true;
    } else {
      this.route.queryParams.subscribe(params => {
        this.setParams({
          categoryId: params['category_id'],
          top: typeof params['top'] === 'undefined' ? -1 : parseInt(params['top'], 10),
          shownegative: !!params.shownegative && params.shownegative.toLowerCase() === 'true'
        });
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkAndUpdateScreenSize();
  }

  private setParams({ categoryId, top, shownegative }): void {
    this.categoryId = categoryId;
    this.top = top;
    this.shownegative = shownegative;
    this.fetchCategoryGames();
    this.getCloudFrontUrl();
  }

  getCloudFrontUrl() {
    this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-bg-color', "#252F68");
    this.apiService.getCloudfrontOpenURL().subscribe((url: { URL }) => {
      this.cloudfrontOpenURL = url.URL;
		  this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-bg-image', `url('/assets/mobile-background.png')`);
      this.route.url.subscribe((urls) => {
        urls.forEach(url => {
          if (url.toString().indexOf("liveleaderboard_citadelsecurities") != -1) {
            this.isCitadelSecurities = true;
            this.isBaml = false;
            this.isDare = false;
            this.leaderboardTitle = "Live Leaderboard";
            this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-bg-image', `url('https://${this.cloudfrontOpenURL}/categories/citadelsecurities/4k_citadelsecurities_leaderboard_bg.png')`);
            this.element.nativeElement.querySelector('.leaderboard').style.setProperty('background-size', 'contain');
            this.element.nativeElement.querySelector('.leaderboard').style.setProperty('background-repeat', 'no-repeat');
            this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-bg-color', "#1b3767");
            this.element.nativeElement.querySelector('.leaderboard').style.setProperty('background-position', "top right");
            this.element.nativeElement.querySelector('.leaderboard-header').style.setProperty('background-color', "transparent");
          } else {
            if (url.toString().indexOf("baml") != -1) {
              this.isCitadelSecurities = false;
              this.isBaml = true;
              this.isDare = false;
              this.leaderboardTitle = "Markets in Action Leaderboards";
              this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-bg-color', "#041c70");
            } else {
              if (url.toString().indexOf("dare") != -1) {
                this.isCitadelSecurities = false;
                this.isBaml = false;
                this.isDare = true;
                this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-bg-color', "#0055fa");
                this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-desc-bar-bg-color', '#000000');
                this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-text-color', '#eaff00');
                this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-header-border', '#ff5762');
              } else {
                if (url.toString().indexOf("leaderboard_citadelsecurities_ghc23") !== -1) {
                  this.showLogo = false;
                  this.isCustomLogo = true;
                  this.isCitadelSecurities = true;
                  this.isBaml = false;
                  this.isDare = false;
                } else if (url.toString().indexOf("liveleaderboard") !== -1) {
                  this.isGenericPage = true;
                  this.defaultLightHeaderColor = '#61A6FA';
                }
              }
            }
          }
        });
      });

      this.fetchCategorySettings();
    });
  }

  fetchCategorySettings() {
    this.apiService.getCategorySettings(this.categoryId).subscribe((settings: Settings[]) => {
      this.settings = settings.length > 0 ? settings[0] : null;
      if (this.settings) {
        this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-text-color', this.settings.ui_text_color);
        this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-top-bar-bg-color', this.settings.ui_top_bar_bg_color);
        this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-desc-bar-bg-color', this.settings.ui_desc_bar_bg_color);

        if (!this.isCitadelSecurities && !this.isBaml) {
          this.element.nativeElement.querySelector('.leaderboard').style.setProperty('--ui-bg-color', this.settings.ui_bg_color);
        }
      }
    });
  }

  fetchCategoryGames() {
    if (this.categoryId) {
      this.apiService.getSingleCategory(this.categoryId).subscribe((categories: Category[]) => {
        this.category = categories[0];
        this.setShowLogo();
        this.getFilteredGames();
      });
    }
  }

  setShowLogo() {
    if (this.category && this.category.small_image) {
      this.showLogo = true;
    } else {
      this.showLogo = false;
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  getLongestListLength(longestList: number) {
    if (this.isCitadelSecurities) {
      if (this.top === -1) {
        return longestList <= 10 ? longestList : 10;
      } else {
        return this.top <= longestList ? this.top : longestList;
      }
    } else {
      if (this.top !== -1 && longestList !== 0) {
        return this.top <= longestList ? this.top : longestList;
      } else {
        return longestList;
      }
    }
  }

  updateBoardIndex() {
    let leaderboardKeys = Object.keys(this.leaderboard).slice(0, 6);
    let longestList = this.leaderboard[leaderboardKeys[0]].length;

    leaderboardKeys.forEach((key) => {
      if (this.leaderboard[key].length > longestList) {
        longestList = this.leaderboard[key].length;
      }
    });

    this.listLength = this.getLongestListLength(longestList);
    this.indexArray = Array.from(Array(this.listLength).keys());
  }

  fetchGameLeaderBoards() {
    this.getFilteredLeaderBoard();

    this.interval = setInterval(() => {
      this.getFilteredLeaderBoard();
    }, 5000);
  }

  getGameLeaderboard(game_id) {
    return this.leaderboard[game_id];
  }

  onVideoPause(evt) {
    evt.preventDefault();
    const video = evt.target;
    video.play();
  }

  async onVideoLoaded(evt) {
    const video = evt.target;
    video.muted = true;
    try {
      await video.play();
    } catch (error) {
      console.log('auto play error', error);
    }
  }

  getFilteredGames() {
    this.paramsToSend.dbName = "games";
    this.paramsToSend.dbColumn = "category_id";
    this.paramsToSend.searchText = this.categoryId;

    this.apiService.getFilteredGames(this.paramsToSend).subscribe((res: { pageOfItems }) => {
      this.games = res.pageOfItems.sort((a, b) => b.order_id - a.order_id); // Sort the items by ' desc order_id;
      this.getGameAutomatedStrategypnl(this.games);
      if (this.isNeuroIPS) {
        this.availableNeuroIPSDates = this.createNeuroIPSAvailableDates();
      }
      this.updateTableColors();
    });
  }

  updateTableColors(): void {
    const headerClasses = ['game-header-1', 'game-header-2', 'game-header-3'];
    if (this.isCustomLogo) {
      for (let i = 0; i < this.games.length; i++) {
        this.games[i].headerStyle = headerClasses[i % headerClasses.length];
      }
    }
    if (this.isGenericPage) {
      const headerColors = this.generateColorOpacity(this.defaultLightHeaderColor, this.games.length);
      for (let i = 0; i < this.games.length; i++) {
        this.games[i].headerColor = headerColors[i % headerColors.length];
      }
    }
  }
  generateColorOpacity(color: string, levels: number) {
  const hex = color.replace('#', '');

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  const darkestHex = this.defaultDarkHeaderColor.replace('#', '');
  const darkestR = parseInt(darkestHex.slice(0, 2), 16);
  const darkestG = parseInt(darkestHex.slice(2, 4), 16);
  const darkestB = parseInt(darkestHex.slice(4, 6), 16);

  const colors = [color];

  for (let i = 1; i < levels - 1; i++) {
      const factor = i / (levels - 1);
      const newR = Math.round(r + (darkestR - r) * factor);
      const newG = Math.round(g + (darkestG - g) * factor);
      const newB = Math.round(b + (darkestB - b) * factor);
      const shadeHex = `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
      colors.push(shadeHex);
    }
    if (levels > 1) {
      colors.push(this.defaultDarkHeaderColor);
    }

    return colors.reverse();
  }
  getFilteredLeaderBoard() {
    this.games.forEach((game: Game) => {
      this.paramsToSend.returnSize = this.top;
      this.paramsToSend.shownegative = this.shownegative;
      this.paramsToSend.id = game.id;

      this.apiService.getFilteredLeaderBoard(this.paramsToSend).subscribe((leaderBoard: Leaderboard[]) => {
        this.leaderboard[game.id] = this.isCitadelSecurities || this.isBaml || this.isDare ? leaderBoard.filter((obj) => obj.points >= 0) : leaderBoard;
        this.updateBoardIndex();
      });
    });
  }

  getGameAutomatedStrategypnl(games: Game[]) {
    let gameAutomatedStrategypnlList = [];
    games.forEach((game: Game) => {
      const automatedStrategyPnl$ = this.apiService.gameAutomatedStrategypnl(game.id).pipe(
        catchError((error: any) => {
          console.log('error in observable', error);
          return of({...game, strategyName: null, PnL: null})
        })
      );
      gameAutomatedStrategypnlList.push(automatedStrategyPnl$);
    });

    forkJoin(gameAutomatedStrategypnlList).subscribe((results) => {
      results.forEach((result:any, index) => {
        const game = games[index];
        games[index] = {...game, strategyName: result.name, PnL: result.pnl};
      });

      this.games = games;
      this.showStrategyAndPnL = this.games.some(game => game.strategyName !== undefined && game.strategyName !== null);
      this.fetchGameLeaderBoards();
      this.selectedTable = !!this.selectedTable ? this.selectedTable : this.games.find(config => config !== null);
      this.selectedTableId = this.selectedTable.id;
      this.initializeDefaultNeuroIPSDate(this.availableNeuroIPSDates, this.path);
    },
    (error) => {
      console.log('forkJoin error', error);
    });
  }

  onTableSelectionChange() {
      this.selectedTable = this.games.find(config => config.id === this.selectedTableId);
  }

  onDateSelectionChange() {
    this.selectedDate = this.availableNeuroIPSDates.find(availableDates => availableDates.id === this.selectedDateId);
    this.redirectToNeuroIPSPage();
  }

  checkAndUpdateScreenSize() {
    this.screenWidth = screen.width;
    this.isMobileView = this.screenWidth <=  912;
  }

  createNeuroIPSAvailableDates(){
    if (!this.isProduction) {
      return [
        {
          name: 'Dec 05',
          month: 12,
          day: 5,
          id: 1,
          value: 'liveleaderboard/CitadelNeurIPSdev1'
        },
        {
          name: 'Dec 06',
          month: 12,
          day: 6,
          id: 2,
          value: 'liveleaderboard/CitadelNeurIPSdev2'
        },
        {
          name: 'Dec 07',
          month: 12,
          day: 7,
          id: 3,
          value: 'liveleaderboard/CitadelNeurIPSdev3'
        },
        {
          name: 'Dec 08',
          month: 12,
          day: 8,
          id: 8,
          value: 'liveleaderboard/CitadelNeurIPSdev4'
        }
      ];
    } else {
      return [
        {
          name: 'Dec 11',
          month: 12,
          day: 11,
          id: 4,
          value: 'liveleaderboard/CitadelNeurIPSDec11'
        },
        {
          name: 'Dec 12',
          month: 12,
          day: 12,
          id: 5,
          value: 'liveleaderboard/CitadelNeurIPSDec12'
        },
        {
          name: 'Dec 13',
          month: 12,
          day: 13,
          id: 6,
          value: 'liveleaderboard/CitadelNeurIPSDec13'
        },
        {
          name: 'Dec 14',
          month: 12,
          day: 14,
          id: 7,
          value: 'liveleaderboard/CitadelNeurIPSDec14'
        },
      ];
    }
  }
  redirectToNeuroIPSPage() {
    this.router.navigate([this.selectedDate.value]);
  }

  initializeDefaultNeuroIPSDate(availabeDates, fullPath) {
    if (this.selectedDateId) {
      this.selectedDate = availabeDates.find(availableDates => availableDates.id === this.selectedDateId);
    } else {
      this.selectedDate = !!availabeDates.find(eventDate => eventDate.value === fullPath) ?
        availabeDates.find(eventDate => eventDate.value === fullPath) : this.availableNeuroIPSDates.find(dates => dates !== null);
      this.selectedDateId = this.selectedDate.id;
    }
  }

  getDomainName(): void {
    const fullUrl = window.location.href;
    const url = new URL(fullUrl);
    const domainName = url.hostname;
    this.isProduction = domainName === 'retrader.amplifyme.com';
  }
}
